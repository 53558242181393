(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
$(document).ready(function () {
    let source, medium, term, content, campaign, session_count, pageview_count;

    function get_campaign_info() {
        var utma = get_utm_value(document.cookie, '__utma=', ';');
        var utmb = get_utm_value(document.cookie, '__utmb=', ';');
        var utmc = get_utm_value(document.cookie, '__utmc=', ';');
        var utmz = get_utm_value(document.cookie, '__utmz=', ';');

        source = get_utm_value(utmz, 'utmcsr=', '|');
        medium = get_utm_value(utmz, 'utmcmd=', '|');
        term = get_utm_value(utmz, 'utmctr=', '|');
        content = get_utm_value(utmz, 'utmcct=', '|');
        campaign = get_utm_value(utmz, 'utmccn=', '|');

        session_count = get_session_count(utma);
        pageview_count = get_pageview_count(utmb, utmc);
    }

    function get_utm_value(l, n, s) {
        if (!l || l == "" || !n || n == "" || !s || s == "") return "-";
        var i, j, k, utm = "-";
        i = l.indexOf(n);
        k = n.indexOf("=") + 1;

        if (i > -1) {
            j = l.indexOf(s, i);
            if (j < 0) {
                j = l.length;
            }
            utm = l.substring((i + k), j);
        }
        return utm;
    }

    //This function extracts the "Count of Sessions" value from the _utma cookie
    function get_session_count(str) {
        var i, vc = '-';
        if (str != '-') {
            i = str.lastIndexOf(".");
            i++;
            vc = str.substring(i);
        }
        return vc;
    }

    //This function extracts the "Count of Pageviews" value from the _utmb cookie
    function get_pageview_count(utmb, utmc) {
        var i, j, pc = '-';
        if (utmb != '-' && utmc != '-') {
            utmc = utmc + '.';

            i = utmc.length;
            j = utmb.indexOf(".", i);
            pc = utmb.substring(i, j);
        }
        return pc;
    }

    function setCookie(name, value, expiryDays) {
        const date = new Date(new Date().getTime() + expiryDays * 24 * 60 * 60 * 1000).toUTCString();
        document.cookie = `${name}=${value};path=/;expires=${date}`;
    }

    function getCookie(name) {
        const escapedName = name.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
        const matches = document.cookie.match(`(?:^|; )${escapedName}=([^;]*)`);
        return matches ? decodeURIComponent(matches[1]) : null;
    }

    function getGaValue() {
        const match = document.cookie.match(/_ga=([^;]*)/);
        const raw = match ? decodeURIComponent(match[1]) : null;
        return raw ? raw.match(/(\d+\.\d+)$/)[1] : null;
    }

    // Function to extract query parameters from the URL
    function getQueryParam(name) {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(name);
    }

    // Function to find the first existing click ID
    function getFirstClickId() {
        const params = ['fbclid', 'gclid', 'li_fat_id', 'msclkid']; // List of query parameter names to check
        return params.reduce((acc, param) => acc || getQueryParam(param), null);
    }

    // Function to collect all required UTM parameters, referral, and the current page URL
    function collectAdditionalParams() {
        const params = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
        const additionalParams = {};
        params.forEach(param => {
            const value = getQueryParam(param);
            if (value !== null) {
                additionalParams[param] = value;
            }
        });
        additionalParams.referrer = document.referrer; // Capture the HTTP referrer
        additionalParams.url = window.location.origin + window.location.pathname; // The sanitized URL without query parameters
        return additionalParams;
    }

    function onTidioChatApiReady() {
        var clickId = getFirstClickId();
        var additionalParams = collectAdditionalParams();

        var tidioParams = {};
        for (var key in additionalParams) {
            if (additionalParams.hasOwnProperty(key)) {
                tidioParams[key] = additionalParams[key];
            }
        }
        if (clickId) {
            tidioParams.clickid = clickId;
        }

        var keysLength = Object.keys(tidioParams).length;
        if (keysLength > 0) {
            tidioChatApi.setContactProperties(tidioParams);
        }
    }

    function setStorageItem(key, value) {
        window.localStorage.setItem(key, value);
    }

    function getStorageItem(key) {
        return window.localStorage.getItem(key);
    }

    function processStorage(utmKey, utmValue) {
        const clIds = ['fbclid', 'gclid', 'li_fat_id', 'msclkid'];

        if (clIds.includes(utmKey)) {
            setCookie('cltype', utmKey, 120);
        }

        if (!getStorageItem(utmKey) && utmValue) {
            if (clIds.includes(utmKey)) {
                if (!getStorageItem('clid')) {
                    setStorageItem('cltype', utmKey);
                    setStorageItem('clid', utmValue);
                }
            } else {
                setStorageItem(utmKey, utmValue);
            }
        }
    }

    function processClickId() {
        const clIds = ['fbclid', 'gclid', 'li_fat_id', 'msclkid'];
        //check query parameters
        clIds.forEach(param => {
            if (getQueryParam(param) !== null) {
                setCookie(param, getQueryParam(param), 120);
                setCookie('cltype', param, 120);
                $('input[name="clid"]').val(getQueryParam(param));
                $('input[name="cltype"]').val(param);

                processStorage(param, getQueryParam(param));
            }
        });
        //check cookies and get first clid
        if (!$('input[name="clid"]').val()) {
            clIds.forEach(param => {
                if (getCookie(param) !== null && !$('input[name="clid"]').val()) {
                    $('input[name="clid"]').val(getCookie(param));
                    $('input[name="cltype"]').val(param);

                    processStorage(param, getCookie(param));
                }
            });
        }

        $('input[name="clid1"]').val(getStorageItem('clid'));
        $('input[name="cltype1"]').val(getStorageItem('cltype'));
    }

    get_campaign_info();

    function get_campaign_info() {
        var utma = get_utm_value(document.cookie, '__utma=', ';');
        var utmb = get_utm_value(document.cookie, '__utmb=', ';');
        var utmc = get_utm_value(document.cookie, '__utmc=', ';');
        var utmz = get_utm_value(document.cookie, '__utmz=', ';');

        source = get_utm_value(utmz, 'utmcsr=', '|');
        medium = get_utm_value(utmz, 'utmcmd=', '|');
        term = get_utm_value(utmz, 'utmctr=', '|');
        content = get_utm_value(utmz, 'utmcct=', '|');
        campaign = get_utm_value(utmz, 'utmccn=', '|');

        session_count = get_session_count(utma);
        pageview_count = get_pageview_count(utmb, utmc);
    }

    function get_utm_value(l, n, s) {
        if (!l || l == "" || !n || n == "" || !s || s == "") return "-";
        var i, j, k, utm = "-";
        i = l.indexOf(n);
        k = n.indexOf("=") + 1;

        if (i > -1) {
            j = l.indexOf(s, i);
            if (j < 0) {
                j = l.length;
            }
            utm = l.substring((i + k), j);
        }
        return utm;
    }

    function get_session_count(str) {
        var i, vc = '-';
        if (str != '-') {
            i = str.lastIndexOf(".");
            i++;
            vc = str.substring(i);
        }
        return vc;
    }

    function get_pageview_count(utmb, utmc) {
        var i, j, pc = '-';
        if (utmb != '-' && utmc != '-') {
            utmc = utmc + '.';

            i = utmc.length;
            j = utmb.indexOf(".", i);
            pc = utmb.substring(i, j);
        }
        return pc;
    }

    function setCookie(name, value, expiryDays) {
        var date = new Date(new Date().getTime() + expiryDays * 24 * 60 * 60 * 1000).toUTCString();
        document.cookie = name + "=" + value + ";path=/;expires=" + date;
    }

    function getCookie(name) {
        var escapedName = name.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
        var matches = document.cookie.match('(?:^|; )' + escapedName + '=([^;]*)');
        return matches ? decodeURIComponent(matches[1]) : null;
    }

    function getGaValue() {
        var match = document.cookie.match(/_ga=([^;]*)/);
        var raw = match ? decodeURIComponent(match[1]) : null;
        return raw ? raw.match(/(\d+\.\d+)$/)[1] : null;
    }

    function getQueryParam(name) {
        var urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(name);
    }

    function getFirstClickId() {
        var params = ['fbclid', 'gclid', 'li_fat_id', 'msclkid'];
        return params.reduce(function(acc, param) {
            return acc || getQueryParam(param);
        }, null);
    }

    function collectAdditionalParams() {
        var params = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
        var additionalParams = {};
        params.forEach(function(param) {
            var value = getQueryParam(param);
            if (value !== null) {
                additionalParams[param] = value;
            }
        });
        additionalParams.referrer = document.referrer;
        additionalParams.url = window.location.origin + window.location.pathname;
        return additionalParams;
    }

    function onTidioChatApiReady() {
        var clickId = getFirstClickId();
        var additionalParams = collectAdditionalParams();

        var tidioParams = {};
        for (var key in additionalParams) {
            if (additionalParams.hasOwnProperty(key)) {
                tidioParams[key] = additionalParams[key];
            }
        }
        if (clickId) {
            tidioParams.clickid = clickId;
        }

        var keysLength = Object.keys(tidioParams).length;
        if (keysLength > 0) {
            tidioChatApi.setContactProperties(tidioParams);
        }
    }

    function setStorageItem(key, value) {
        window.localStorage.setItem(key, value);
    }

    function getStorageItem(key) {
        return window.localStorage.getItem(key);
    }

    function processStorage(utmKey, utmValue) {
        var clIds = ['fbclid', 'gclid', 'li_fat_id', 'msclkid'];

        if (clIds.indexOf(utmKey) !== -1) {
            setCookie('cltype', utmKey, 120);
        }

        if (!getStorageItem(utmKey) && utmValue) {
            if (clIds.indexOf(utmKey) !== -1) {
                if (!getStorageItem('clid')) {
                    setStorageItem('cltype', utmKey);
                    setStorageItem('clid', utmValue);
                }
            } else {
                setStorageItem(utmKey, utmValue);
            }
        }
    }

    function processClickId() {
        var clIds = ['fbclid', 'gclid', 'li_fat_id', 'msclkid'];
        clIds.forEach(function(param) {
            if (getQueryParam(param) !== null) {
                setCookie(param, getQueryParam(param), 120);
                setCookie('cltype', param, 120);
                $('input[name="clid"]').val(getQueryParam(param));
                $('input[name="cltype"]').val(param);

                processStorage(param, getQueryParam(param));
            }
        });

        if (!$('input[name="clid"]').val()) {
            clIds.forEach(function(param) {
                if (getCookie(param) !== null && !$('input[name="clid"]').val()) {
                    $('input[name="clid"]').val(getCookie(param));
                    $('input[name="cltype"]').val(param);

                    processStorage(param, getCookie(param));
                }
            });
        }

        $('input[name="clid1"]').val(getStorageItem('clid'));
        $('input[name="cltype1"]').val(getStorageItem('cltype'));
    }

    get_campaign_info();

    var hiddenInputs = [
        'p', 'client_id', 'campaign_source', 'campaign_channel', 'campaign_name', 'campaign_id',
        'ad_group', 'search_queries', 'search_terms', 'session_count', 'matchtype', 'device', 'clid', 'cltype'
    ];

    $('.formWP').append('<div class="storedValues hidden"></div>');
    ['.formWP', '.storedValues'].forEach(function (div) {
        var $container = $(div);
        var $inputs = $('<div style="display:none"></div>');

        hiddenInputs.forEach(function (input) {
            $inputs.append('<input type="hidden" name="' + input + (div.indexOf('storedValues') !== -1 ? '1' : '') + '" value="">');
        });

        $container.append($inputs);
    });

// get utm from url
    var searchParams = new URLSearchParams(window.location.search);
    var queryDict = {};

    searchParams.forEach(function (value, key) {
        queryDict[key] = value;
    });

    var utm_list = [
        { key: 'p', sugar: 'p' },
        { key: 'utm_source', sugar: 'campaign_source' },
        { key: 'utm_medium', sugar: 'campaign_channel' },
        { key: 'utm_campaign', sugar: 'campaign_name' },
        { key: 'utm_id', sugar: 'campaign_id' },
        { key: 'utm_term', sugar: 'search_queries' }, //Keyword
        { key: 'utm_content', sugar: 'ad_group' },
        { key: 'term', sugar: 'search_terms' },
        { key: 'matchtype', sugar: 'matchtype' },
        { key: 'device', sugar: 'device' }
    ];

    utm_list.forEach(function (utm_item) {
        var utmKey = utm_item.key;
        var utmValue = '';

        if (utmKey in queryDict) {
            setCookie(utmKey, queryDict[utmKey], 120);
            utmValue = decodeURIComponent(queryDict[utmKey]);
        } else {
            var cookieValue = getCookie(utmKey);
            if (cookieValue !== null) {
                utmValue = cookieValue;
            }
        }

        processStorage(utmKey, utmValue);

        if ($('input[name="' + utm_item.sugar + '"]').val() === '') {
            $('input[name="' + utm_item.sugar + '"]').val(utmValue);
        }

        //check first input
        if (getStorageItem(utmKey)) {
            $('form div.storedValues input[name="' + utm_item.sugar + '1"]').val(getStorageItem(utmKey));
        }
    });

    processClickId();

    $('input[name="url_page"]').val(window.location.href);
    $('input[name="client_id"]').val(getGaValue());
    $('input[name="session_count"]').val(session_count);

    if (window.location.href.indexOf("utm_campaign") !== -1) {
        setCookie('url_page', window.location.href, 120);
    }

// Check if Tidio chat API is available and listen for it to be ready
    if (window.tidioChatApi) {
        window.tidioChatApi.on("ready", onTidioChatApiReady);
    } else {
        document.addEventListener("tidioChat-ready", onTidioChatApiReady);
    }
});
},{}]},{},[1]);
